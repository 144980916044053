import React from "react";
import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import CountUp from "react-countup";

export default function CountUpBox({ emoji, name, count, extraText = null }) {
    return (
      <Box
      bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
              p={1}
              rounded="2xl"
              shadow="lg"
              
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Text
                fontSize="sm"
                fontWeight="bold"
                textAlign="center"
              
              >
                {emoji}
              </Text>
              <Text
                fontSize="sm"
                fontWeight="bold"
                textAlign="center"
                color={useColorModeValue("gray.900", "gray.500")}
              >
                {name} 
              </Text>
              <Text
                fontSize="lg"
                fontWeight="bold"
                textAlign="center"
                // color="main.primary"
              >
                <CountUp end={count} useEasing={true} duration={1} />
                {extraText}
              </Text>
            </Box>
    );
  }
  