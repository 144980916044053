import React, { useState } from "react";

import {
  Box,
  Flex,
  Text,
  Avatar,
  Badge,
  IconButton,
  HStack,
  Button,
  Collapse,
  Textarea,
  Input,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { useToast } from "@chakra-ui/react";
import DateEditedHStack from "./DateEditedHStack.js";
import DeleteEditButtons from "../components/DeleteEditButtons.js";
import Answer from "../components/Answer.js";
import ReportButton from "../components/ReportButton.js";
import LikeDislikeButton from "../components/LikeDislikeButton.js";
import { AiOutlineSend } from "react-icons/ai";
import { editElement } from "../components/DeleteEditButtons.js";
import request from "../api.js";
import "./transitions.css";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";

export default function Question({
  username,
  karma,
  avatarID,
  text,
  date,
  edited,
  authorDeleted,
  postedBy,
  likes,
  dislikes,
  isLikedByUser,
  isDislikedByUser,
  isAuthor,
  isAdmin,
  answers,
  courseID,
  id,
  fetchQuestions,
  updateUser,
  setUpdateUser,
  translation,
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditingQuestion, setIsEditingQuestion] = useState(false);
  const { t, i18n } = useTranslation();
  const [editedQuestion, setEditedQuestion] = useState(text);
  const [reply, setReply] = useState("");
  const [showTranslation, setShowTranslation] = useState(false);
  const toast = useToast();
  const fetchedText = text;

  const toggleTranslation = () => {
    setShowTranslation(!showTranslation);
  };

  const handleReply = async () => {
    let response;
    try {
      response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${courseID}/questions/${id}/answer`,
        "POST",
        { answerText: reply.trim() }
      );
      toast({
        title: [t("answer_posted")],
        description: [t("you_are_a_hero")],
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      fetchQuestions();
      setUpdateUser(!updateUser);
    } catch (error) {
      toast({
        title: t("error_modal_title"),
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    try {
      await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${courseID}/translate`,
        "POST",
        { text: reply.trim(), elementID: response.id, elementType: "answer" }
      );
      setReply("");


    } catch (error) {
      setReply("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      event.target.value = "";
      handleReply();
    }
  };
  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const handleQuestionEdit = async () => {
    if (editedQuestion.trim() == fetchedText.trim()) return;
    if (editedQuestion.trim() == "") {
      toast({
        title: "Empty question",
        description:
          "If you want to delete the question, hit the delete button.",
        status: "info",
        duration: 2000,
        isClosable: true,
      });
      setEditedQuestion(fetchedText);
      setIsEditingQuestion(false);
      return;
    }

    const success = await editElement(
      editedQuestion,
      courseID + "/questions/" + id,
      "question",
      toast
    );
    fetchQuestions();
    if (!success) setEditedAnswer(fetchedText);
    setIsEditingQuestion(false);
    if (success) {
      try {
        await request(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${courseID}/translate`,
          "POST",
          {
            text: editedQuestion.trim(),
            elementID: id,
            elementType: "question",
          }
        );
      } catch (error) { console.error("Translation failed: ", error) }
    }
  };

  return (
    <Box
      px={2}
      py={1}
      mb={2}
      bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
      shadow="lg"
      borderRadius={"lg"}
    >
      <Flex alignItems="center" mb={2} mr={1}>
        <Avatar
          color="gray.900"
          name={authorDeleted ? null : username}
          src={
            !authorDeleted && avatarID ? `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/user/avatar/${avatarID}` : null
          }
          size="md"
          mr={2}
        />
        <Box flex="1">
          <HStack spacing={2}>
            <Flex>
              <Text fontWeight={!authorDeleted && "bold"}>
                {authorDeleted ? t("deleted") : username}
              </Text>
              {isAuthor && !authorDeleted && <Text ml={1}>{"(" + t("you") + ")"} </Text>}
            </Flex>
            {!authorDeleted && (
              <Badge colorScheme="light-grey">🔥 {karma}</Badge>
            )}
            {isAuthor || isAdmin ? (
              <DeleteEditButtons
                typeName={"question"}
                isEditing={isEditingQuestion}
                setIsEditing={setIsEditingQuestion}
                route={`${courseID}/questions/${id}`}
                fetchData={fetchQuestions}
                isAuthor={isAuthor}
                updateUser={updateUser}
                setUpdateUser={setUpdateUser}
              />
            ) : (
              <ReportButton
                courseID={courseID}
                elementType={"question"}
                elementId={id}
              />
            )}
          </HStack>
          <DateEditedHStack date={date} edited={edited} />

          <Box flex={1}>
            {isEditingQuestion ? (
              <Textarea
                value={editedQuestion}
                onChange={(e) => setEditedQuestion(e.target.value)}
                mb={2}
                resize="none"
              />
            ) : (
              <Text as={showTranslation && translation.language === i18n.language ? "i" : null}>
                {showTranslation && translation.language === i18n.language ? translation.text : editedQuestion}
                {translation && translation.language === i18n.language && (
                  <Button
                    variant="link"
                    color="main.primary"
                    size="sm"
                    ml={2}
                    onClick={toggleTranslation}
                  >
                    {showTranslation ? t("show_original") : t("translate")}
                  </Button>
                )}
              </Text>
            )}

            <Box d="flex" justifyContent="flex-end" alignSelf={"right"}>
              {isEditingQuestion ? (
                <HStack spacing={2} mb={1}>
                  <Box flex="1" />
                  <Button
                    colorScheme="gray"
                    size="sm"
                    onClick={() => {
                      setEditedQuestion(fetchedText);
                      setIsEditingQuestion(false);
                    }}
                    mr={2}
                  >
                    {t("cancel")}
                  </Button>

                  <Button
                    color="white"
                    bg="main.primary"
                    _hover={{ bg: "main.secondary" }}
                    size="sm"
                    onClick={handleQuestionEdit}
                    justifyContent={"flex-end"}
                  >
                    {t("save")}
                  </Button>
                </HStack>
              ) : null}
            </Box>
          </Box>
          <HStack spacing={0}>
            <Textarea
              value={reply}
              rows={1}
              height="auto"
              placeholder={t("reply")}
              size={"xs"}
              fontSize={"sm"}
              resize={"vertical"}
              onChange={(e) => setReply(e.target.value)}
              onKeyDown={handleKeyDown}
              mb={1}
            />
            {reply.trim() !== "" && (
              <IconButton
                p={0}
                m={0}
                aria-label="Reply"
                icon={<AiOutlineSend />}
                size={"sm"}
                fontSize={"2xl"}
                variant="ghost"
                color="main.primary"
                onClick={handleReply}
              />
            )}
          </HStack>
        </Box>
        <LikeDislikeButton
          likes={likes}
          dislikes={dislikes}
          isLikedByUser={isLikedByUser}
          isDislikedByUser={isDislikedByUser}
          route={courseID + "/questions/" + id}
          fetchData={fetchQuestions}
          updateUser={updateUser}
          setUpdateUser={setUpdateUser}
        />
      </Flex>
      {answers.length > 0 && (
        <Box key={0} ml={12}>
          <Answer
            {...answers[0]}
            courseID={courseID}
            questionId={id}
            fetchQuestions={fetchQuestions}
            isAdmin={isAdmin}
            updateUser={updateUser}
            setUpdateUser={setUpdateUser}
          ></Answer>
        </Box>
      )}
      <Collapse in={isExpanded} animateOpacity>
        <TransitionGroup>
          {answers.slice(1).map((answer, index) => (
            <CSSTransition key={answer.id} classNames="fade" timeout={300}>
              <Box key={index + 1} ml={12}>
                <Answer
                  {...answer}
                  courseID={courseID}
                  questionId={id}
                  fetchQuestions={fetchQuestions}
                  isAdmin={isAdmin}
                  updateUser={updateUser}
                  setUpdateUser={setUpdateUser}
                ></Answer>
              </Box>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </Collapse>

      {answers.length > 1 ? (
        <Flex justifyContent="center" mt={2}>
          <Button
            size="sm"
            variant="ghost"
            colorScheme="gray"
            onClick={handleExpand}
          >
            {isExpanded ? t("hide_answers") : t("show_all_answers")}
          </Button>
        </Flex>
      ) : null}
    </Box>
  );
}
