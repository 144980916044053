import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  HStack,
  Stack,
  Flex,
  Button,
  Divider,
  Badge,
  IconButton,
  Select,
  Textarea,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Spacer,
  useColorModeValue,
  Tooltip,
} from "@chakra-ui/react";

import { CheckIcon, EditIcon } from "@chakra-ui/icons";
import { IoHeartSharp, IoHeartOutline } from "react-icons/io5";
import { useMediaQuery } from "@chakra-ui/react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import RatingButtonWithModal from "./RatingButtonWithModal";
import VerifyEMailComponent from "./VerifyEMailComponent";
import CourseDetails from "./CourseDetails";
import CourseStats from "./CourseStats";
import BottomBar from "./BottomBar";
import EditCourseDetails from "./EditCourseDetails";
import Comment from "./Comment";
import AskQuestionTemplate from "./AskQuestionTemplate";
import Question from "./Question";
import { useTranslation } from "react-i18next";
import { nameTranslation } from "./utils/Utils";

export default function CourseMobile({
  isSignedIn,
  allowedRoles,
  role,
  isEditingCourseName,
  editedCourseName,
  setEditedCourseName,
  handleCourseNameChange,
  emailVerified,
  hasRated,
  hasFaved,
  favCourse,
  courseData,
  courseCode,
  fetchQuestionsAndComments,
  updateUser,
  setUpdateUser,
  isEditingDetails,
  setIsEditingDetails,
  handleSignIn,
  commentData,
  questionData,
  handleCommentSelectChange,
  fetchCourseData,
  setHasRated,
  navigate,
}) {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Box
        alignItems="center"
        mb={4}
        position="sticky"
        top="79"
        zIndex={2}
        bgGradient={useColorModeValue(
          "linear( rgba(237, 242, 247,1) , rgba(237, 242, 247,0.1))",
          "linear( rgba(45, 55, 72,1) , rgba(45, 55, 72, 0.1))"
        )}
      >
        {isSignedIn && allowedRoles.includes(role) && <VerifyEMailComponent />}

        <Box flex="1" width="100%" mt={2}>
          {isEditingCourseName ? (
            <HStack>
              <Textarea
                value={editedCourseName}
                onChange={(e) => setEditedCourseName(e.target.value)}
                size="lg"
              />
              <IconButton
                aria-label="Save course name"
                icon={<CheckIcon />}
                size="sm"
                color="main.primary"
                variant={"ghost"}
                onClick={handleCourseNameChange}
              />
            </HStack>
          ) : (
            <HStack mx={4}>
              <Text
                my={2}
                fontSize={
                  nameTranslation(courseData?.name, i18n.language).length > 80
                    ? "xl"
                    : "2xl"
                }
                fontWeight="bold"
                style={{ lineHeight: "1" }}
              >
                {nameTranslation(courseData?.name, i18n.language)}
              </Text>
              {isSignedIn && <Tooltip label={hasFaved ? t("unfav_course") : t("fav_course")}  >
                <IconButton
                  aria-label="Fav Course"
                  icon={
                    hasFaved ? (
                      <IoHeartSharp color="main.primary" />
                    ) : (
                      <IoHeartOutline color="main.elementBgLight" />
                    )
                  }
                  size="lg"
                  color="main.primary"
                  variant={"ghost"}
                  onClick={() => {
                    favCourse(courseData?.id);
                  }}
                />
              </Tooltip>}
              {courseData?.isAdmin && (
                <IconButton
                  aria-label="Edit course details"
                  icon={<EditIcon />}
                  size="sm"
                  color="main.primary"
                  variant={"ghost"}
                // onClick={() => setIsEditingCourseName(true)}
                />
              )}
            </HStack>
          )}

          {!emailVerified ||
            hasRated ||
            courseData?.ratingCount == 0 ||
            !(isSignedIn && allowedRoles.includes(role)) ? null : (
            <Box mx={4}>
              <RatingButtonWithModal
                courseID={courseData?.id}
                setHasRated={setHasRated}
                fetchComments={fetchQuestionsAndComments}
                fetchCourseData={fetchCourseData}
                updateUser={updateUser}
                setUpdateUser={setUpdateUser}
              />
            </Box>
          )}
        </Box>

        <HStack direction="row" justify="space-between" mx={8}>
          <Tooltip label={t("stars")}>

            <Box align="center" mr={1}>
              <Text fontSize="2xl" fontWeight="bold">
                ⭐️ {courseData?.averageRating ? courseData?.averageRating.toFixed(1) : "-"}
              </Text>
            </Box>
          </Tooltip>
          <Tooltip label={t("would_recommend")}>
            <Box align="center">
              <Text fontSize="2xl" fontWeight="bold">
                👍{" "}
                {courseData?.ratingCount > 0
                  ? (courseData?.wouldRecommend * 100).toFixed() + " %"
                  : "-"}
              </Text>

            </Box>
          </Tooltip>

          <Tooltip label={t("average_grade")}>
            <Box align="center">
              <Text fontSize="2xl" fontWeight="bold">
                🎓{" "}
                {courseData?.ratingCount > 1
                  ? courseData?.averageGrade.toFixed(1)
                  : "-" || "-"}
              </Text>
            </Box>
          </Tooltip>
        </HStack>
      </Box>
      <Box mx={4} pos="relative" top="75" >
        <Box my={4} mt="0rem" >
          <Stack direction="row" justify="space-between" width="full">
            {isEditingDetails ? (
              <EditCourseDetails
                courseData={courseData}
                courseID={courseData?.id}
                setIsEditing={setIsEditingDetails}
                fetchCourseData={fetchCourseData}
              />
            ) : (
              <Box alignItems={"stretch"} mt={1} spacing={2} width="full">
                <Box
                  mb={6}
                  flex={1}
                  flexGrow={1}
                  flexShrink={0}
                  shadow="lg"
                  borderRadius="lg"
                  bg={useColorModeValue(
                    "main.elementBgLight",
                    "main.elementBgDark"
                  )}
                >
                  <HStack spacing={4} px={4} py={2} justify="space-between">
                    <Text fontSize="2xl" fontWeight="bold" ml={2}>
                      {t("details")}
                    </Text>
                    <Box flex={1} />
                    {courseData?.isAdmin ? (
                      <Button
                        onClick={() => setIsEditingDetails(true)}
                        variant="outline"
                        color="main.primary"
                        size="sm"
                        rightIcon={<EditIcon />}
                      >
                        {t("edit")}
                      </Button>
                    ) : (
                      isSignedIn &&
                      allowedRoles.includes(role) &&
                      courseData &&
                      (courseData?.partOfTheDegreeProgram ? (
                        <Badge colorScheme="green" variant="outline">
                          {t("part_of_program")}
                        </Badge>
                      ) : (
                        <Badge colorScheme="red" variant="outline">
                          {t("not_part_of_program")}
                        </Badge>
                      ))
                    )}
                  </HStack>

                  <CourseDetails
                    courseData={courseData}
                    courseCode={courseCode}
                  />
                </Box>

                <CourseStats
                  {...courseData}
                  loggedIn={isSignedIn && allowedRoles.includes(role)}
                  setHasRated={setHasRated}
                  handleSignIn={handleSignIn}
                  fetchComments={fetchQuestionsAndComments}
                  fetchCourseData={fetchCourseData}
                  emailVerified={emailVerified}
                  updateUser={updateUser}
                  setUpdateUser={setUpdateUser}
                  isLargerThan768={false}
                />
              </Box>
            )}
          </Stack>

          <Flex mb={6} position="relative">
            {(!isSignedIn || !emailVerified) && (
              <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                bg={useColorModeValue("rgba(255, 255, 255, 0.7)", "rgba(45, 55, 72, 0.7)")}
                backdropFilter="blur(5px)"
                zIndex="10"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                borderRadius="lg"
              >
                <Text fontSize="lg" fontWeight="bold" textAlign="center">
                  {t("want_to_see_comments_and_ask_questions")}
                </Text>
                {!isSignedIn ? <Flex justifyContent="center" mt={4}>
                  <Box alignItems="center" textAlign="center">
                    <Text fontSize="md" fontWeight="bold">
                      {t("sign_in_or_create_account")}
                    </Text>
                    <HStack mt={4} alignItems="center" textAlign="center">
                      <Spacer />
                      <Button
                        borderColor="main.primary"
                        variant="outline"
                        mr={2}
                        color="main.primary"
                        onClick={handleSignIn}
                      >
                        {t("sign_in")}
                      </Button>
                      <Button
                        bg="main.primary"
                        variant="solid"
                        _hover={{ bg: "main.secondary" }}
                        color="white"
                        onClick={() => {
                          navigate(`/sign-up`);
                        }}
                      >
                        {t("sign_up")}
                      </Button>
                      <Spacer />
                    </HStack>
                  </Box>
                </Flex> : <Text fontSize="xl" fontWeight="bold">
                  {t("verify_email")}
                </Text>}
              </Box>
            )}

            <>
              <Tabs variant="enclosed" w="full" minH={"30vh"} >
                <TabList>
                  <Tab fontWeight={"bold"}> {t("ratings") + " (" + (courseData?.ratingCount || "0") + ")"}</Tab>
                  <Spacer />
                  <Tab fontWeight={"bold"}>  {t("q_and_a")}</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel p={0}>
                    <Box flex="1">
                      <HStack justifyContent="space-between">
                        <Select
                          width="30%"
                          height="1.9rem"
                          bg={useColorModeValue(
                            "main.elementBgLight",
                            "main.elementBgDark"
                          )}
                          onChange={handleCommentSelectChange}
                          mt={2}
                        >
                          <option value="top">{t("top")}</option>
                          <option value="stars">{t("stars")}</option>
                          <option value="newest">{t("newest")}</option>
                          <option value="oldest">{t("oldest")}</option>
                        </Select>
                      </HStack>
                      <Divider my={2} />

                      {commentData != null ? (
                        <TransitionGroup>
                          {commentData.map((comment, index) => (
                            <CSSTransition
                              key={comment.id}
                              classNames="fade"
                              timeout={500}
                            >
                              <Comment
                                key={index}
                                {...comment}
                                courseCode={courseCode}
                                fetchComments={fetchQuestionsAndComments}
                                fetchCourseData={fetchCourseData}
                                isAdmin={courseData?.isAdmin}
                                updateUser={updateUser}
                                setUpdateUser={setUpdateUser}
                              />
                            </CSSTransition>
                          ))}
                        </TransitionGroup>
                      ) : null}
                    </Box>
                  </TabPanel>
                  <TabPanel p={0} w={"full"}>
                    <Box flex="1">
                      <Divider my={2} />
                      <AskQuestionTemplate
                        {...courseData}
                        fetchQuestions={fetchQuestionsAndComments}
                        fetchCourseData={fetchCourseData}
                        updateUser={updateUser}
                        setUpdateUser={setUpdateUser}
                      />

                      {questionData != null ? (
                        <TransitionGroup>
                          {questionData.map((questionData, index) => (
                            <CSSTransition
                              key={questionData.id}
                              classNames="fade"
                              timeout={300}
                            >
                              <Question
                                key={index}
                                {...questionData}
                                courseCode={courseCode}
                                fetchQuestions={fetchQuestionsAndComments}
                                isAdmin={courseData?.isAdmin}
                                updateUser={updateUser}
                                setUpdateUser={setUpdateUser}
                              />
                            </CSSTransition>
                          ))}
                        </TransitionGroup>
                      ) : null}
                    </Box>{" "}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </>

          </Flex>
        </Box>
      </Box>
    </>
  );
}
