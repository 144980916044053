import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Flex,
  Avatar,
  Text,
  VStack,
  HStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Badge,
  Spinner,
  Icon,
  ScaleFade,
  useColorModeValue,
  useMediaQuery
} from "@chakra-ui/react";
import { TbTriangleFilled, TbTriangleInvertedFilled } from "react-icons/tb";

import AuthContext from "../AuthContext";
import request from "../api";
import { useTranslation } from "react-i18next";

function useInterval(callback, delay) {
  const savedCallback = React.useRef();
  
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay !== null) {
      const id = setInterval(() => savedCallback.current(), delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const Leaderboard = () => {
  const { user } = useContext(AuthContext);
  const [period, setPeriod] = useState("week");
  const [leaderboard, setLeaderboard] = useState(null);
  const [loading, setLoading] = useState(true);
  const [timeRemaining, setTimeRemaining] = useState("");
  const { t, i18n } = useTranslation();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  useEffect(() => {
    const fetchLeaderboard = async () => {
      setLoading(true);
      try {
        const response = await request(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/karma-rankings?period=${period}`
        );
        setLeaderboard(response);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch leaderboard:", error);
        setLoading(false);
      }
    };

    fetchLeaderboard();
  }, [period]);

  useInterval(() => {
    setTimeRemaining(getTimeRemaining(period));
  }, 1000);

  const getTimeRemaining = (period) => {
    const now = new Date();
    let endDate;
    if (period === "week") {
      endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + (8 - now.getDay()));
    } else if (period === "month") {
      endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    } else {
      return "";
    }
    const diff = endDate - now;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);
    return `${period === "week" && days == 7 ? 0 : days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  const renderTopUser = (user, index, isCurrentUser = false) => {
    const size = "lg";
 
    const bgColor = isCurrentUser ? useColorModeValue("blue.100", "blue.900") : useColorModeValue("main.elementBgLight", "main.elementBgDark");
    const emoji = index === 0 ? "🥇" : index === 1 ? "🥈" : "🥉";
    return (
      <ScaleFade initialScale={0.9} in key={user?.username}>
        <Flex
          w="100%"
          p={4}
          bg={bgColor}
          shadow="md"
          borderRadius="md"
          alignItems="center"
          justifyContent="space-between"
        >
          <HStack spacing={4} alignItems="center">
            <Avatar
              color="gray.900"
              name={user?.username}
              src={
                user
                  ? `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/user/avatar/${user?.avatar}`
                  : null
              }
              size={size}
              mr={2}
            />
            <Box>
              <Text fontWeight="bold" fontSize={size} >
                {emoji} {isCurrentUser ? t("you") : user?.username}
              </Text>
              <HStack spacing={2}>
                <Badge colorScheme="green" fontSize="md">{t("rank")} {user?.ranking}</Badge>
                {period != "alltime" && <> <Badge colorScheme="yellow" fontSize="md">{isLargerThan768 && t(period) + ":"} 📈 {user?.currentKarma}</Badge>
                  {/* <Badge
                    colorScheme={
                      user?.change > 0
                        ? "green"
                        : user?.change < 0
                          ? "red"
                          : "gray"
                    }
                    fontSize="md"
                  >
                    <Icon as={user?.change < 0 ? TbTriangleInvertedFilled : TbTriangleFilled} mr={1} />
                    {user?.change}
                  </Badge> */}
                </>}
                <Badge colorScheme="blue" fontSize="md">{isLargerThan768 && t("total") +":"}🔥 {user?.totalKarma}</Badge>
              </HStack>
            </Box>
          </HStack>
        </Flex>
      </ScaleFade>
    );
  };

  const renderUserCard = (user, isCurrentUser = false) => (
    <ScaleFade initialScale={0.9} in key={user?.username}>
      <Flex
        w="100%"
        p={4}
        bg={isCurrentUser ? useColorModeValue("blue.100", "blue.900") : useColorModeValue("main.elementBgLight", "main.elementBgDark")}
        shadow="md"
        borderRadius="md"
        alignItems="center"
        justifyContent="space-between"
        // border={isCurrentUser ? "2px solid" : "none"}
        borderColor={isCurrentUser ? "blue.500" : "none"}
      >
        <HStack spacing={4} alignItems="center">
          <Avatar
            color="gray.900"
            name={user?.username}

            src={
              user
                ? `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/user/avatar/${user?.avatar}`
                : null
            }
            size="md"
            mr={2}
          />
          <Box>
            <Text fontWeight="bold" fontSize="lg">
              {isCurrentUser ? t("you") : user?.username}
            </Text>
            <HStack spacing={2}>
              <Badge colorScheme="green" fontSize="md">{t("rank")} {user?.ranking}</Badge>
              {period != "alltime" && <> <Badge colorScheme="yellow" fontSize="md">{isLargerThan768 && t(period) + ":"} 📈 {user?.currentKarma}</Badge>
                {/* <Badge
                  colorScheme={
                    user?.change > 0
                      ? "green"
                      : user?.change < 0
                        ? "red"
                        : "gray"
                  }
                  fontSize="md"
                >
                  <Icon as={user?.change < 0 ? TbTriangleInvertedFilled : TbTriangleFilled} mr={1} />
                  {user?.change}
                </Badge> */}
              </>}
              <Badge colorScheme="blue" fontSize="md">{isLargerThan768 && t("total") +":"}🔥 {user?.totalKarma}</Badge>

            </HStack>
          </Box>
        </HStack>
      </Flex >
    </ScaleFade >
  );

  return (
    <Box
      mt="75"
      mb={4}
      p={4}
      w="100%"
      maxW="800px"
      mx="auto"
    >
      <Text fontSize="3xl" fontWeight="bold" textAlign="center" mb={4}>
        Leaderboard
      </Text>
      <Flex justifyContent="center" alignItems="center" mb={4} flexDirection="column">
        <Text fontSize="lg" mb={2} textAlign="center">
          {t("leaderboard_description")}
        </Text>
        <Tabs variant="soft-rounded" colorScheme="blue" onChange={(index) => setPeriod(index === 0 ? "week" : index === 1 ? "month" : "alltime")}>
          <TabList>
            <Tab fontWeight="bold">{t("this_week")}</Tab>
            <Tab fontWeight="bold">{t("this_month")}</Tab>
            <Tab fontWeight="bold">{t("all_time")}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel />
            <TabPanel />
            <TabPanel />
          </TabPanels>
        </Tabs>
      </Flex>

      <Flex justifyContent="center" alignItems="center" mb={4}>
        {period !== "alltime" && <Text fontSize="2xl" fontWeight="bold" textAlign="center">
          ⏰ {timeRemaining}
        </Text>}
      </Flex>

      {loading ? (
        <Flex justifyContent="center" alignItems="center" height="200px">
          <Spinner size="lg" />
        </Flex>
      ) : (
        <VStack spacing={4} width="100%">
          {leaderboard?.topUsers?.slice(0, 3).map((user, index) => renderTopUser(user, index, leaderboard?.currentUser?.username === user?.username))}
          {/* {leaderboard?.topUsers?.slice(3).map((user) => renderUserCard(user, user?.username === leaderboard?.currentUser?.username))} */}
          {leaderboard?.currentUser && leaderboard?.currentUserRanking > 3 && (
            <>
              <Box w="100%" textAlign="center" p={4}>
                <Text fontSize="lg" fontWeight="bold">{t("you_are_not_top_3")}</Text>
              </Box>
              {renderUserCard(leaderboard.currentUser, true)}
            </>
          )}
        </VStack>
      )}
    </Box>
  );
};

export default Leaderboard;
