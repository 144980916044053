import React from 'react';
import { Box, Heading, Text, Link, List, ListItem } from '@chakra-ui/react';
import Navbar from '../components/Navbar';
import BottomBar from '../components/BottomBar';
import { useContext } from 'react';
import AuthContext from "../AuthContext";

const PrivacyPolicy = () => {
    const allowedRoles = ["ADMIN", "STUDENT", "MODERATOR"];
    const { isSignedIn, role } = useContext(AuthContext);

    return (
        <Box>
            <Navbar loggedIn={isSignedIn && allowedRoles.includes(role)} />
            <Box px={10} py={5} mt={20} maxW={"100vw"}>
                <Heading as="h1" size="xl" mb={4}>
                    Datenschutzerklärung für die classrate Plattform
                </Heading>
                
                <Text mb={4}>
                    Diese Datenschutzerklärung informiert Sie über Art, Umfang und Zweck der Verarbeitung
                    personenbezogener Daten innerhalb unseres Onlineangebots "classrate" und der damit verbundenen
                    Webseiten, Funktionen und Inhalte. Sie richtet sich an die NutzerInnen dieser Plattform und erfüllt
                    die Anforderungen der EU-Datenschutz-Grundverordnung (DSGVO).
                </Text>

                <Heading as="h2" size="lg" mt={6} mb={2}>
                    Verantwortliche Stelle
                </Heading>
                <Text mb={4}>
                    Verantwortlich für die Verarbeitung Ihrer personenbezogenen Daten ist die Betreiberin der
                    classrate-Plattform. Kontaktanfragen richten Sie bitte an die im Impressum hinterlegten Kontaktdaten.
                </Text>

                <Heading as="h2" size="lg" mt={6} mb={2}>
                    Erhobene Daten
                </Heading>
                <Text mb={4}>
                    Wir erheben und speichern folgende personenbezogene Daten:
                </Text>
                <List spacing={2} mb={4}>
                    <ListItem>Email-Adresse</ListItem>
                    <ListItem>Nutzername</ListItem>
                    <ListItem>Studiengangsbezogene Angaben (Fakultät, Abschlusslevel wie Master oder Bachelor, Studiengang)</ListItem>
                </List>

                <Heading as="h2" size="lg" mt={6} mb={2}>
                    Zweck der Verarbeitung und Rechtsgrundlage
                </Heading>
                <Text mb={4}>
                    Die Verarbeitung Ihrer Daten erfolgt zum Zweck der Bereitstellung des Dienstes, der Erhebung und
                    Anzeige von Kursbewertungen und des Austauschs zwischen NutzerInnen. Rechtsgrundlage hierfür ist
                    Art. 6 Abs. 1 lit. b DSGVO (Durchführung vorvertraglicher Maßnahmen und Erfüllung des Vertrages).
                    Wir verwenden Ihre Daten nicht für andere Zwecke und schalten aktuell keine Werbung.
                </Text>

                <Heading as="h2" size="lg" mt={6} mb={2}>
                    Weitergabe und Übermittlung von Daten
                </Heading>
                <Text mb={4}>
                    Wir geben Ihre personenbezogenen Daten nicht an Dritte weiter, es sei denn, wir sind rechtlich
                    dazu verpflichtet oder es liegt eine entsprechende Einwilligung vor.
                </Text>

                <Heading as="h2" size="lg" mt={6} mb={2}>
                    Speicherdauer
                </Heading>
                <Text mb={4}>
                    Wir speichern Ihre personenbezogenen Daten nur so lange, wie es für die Bereitstellung unseres
                    Dienstes und zur Erfüllung rechtlicher Pflichten erforderlich ist. Anschließend werden die Daten
                    gelöscht oder anonymisiert.
                </Text>

                <Heading as="h2" size="lg" mt={6} mb={2}>
                    Sicherheit
                </Heading>
                <Text mb={4}>
                    Wir treffen geeignete technische und organisatorische Maßnahmen, um Ihre Daten vor Verlust,
                    Missbrauch, unbefugtem Zugriff, Offenlegung und Änderung zu schützen.
                </Text>

                <Heading as="h2" size="lg" mt={6} mb={2}>
                    Rechte der betroffenen Personen
                </Heading>
                <Text mb={4}>
                    Sie haben das Recht, Auskunft über Ihre gespeicherten personenbezogenen Daten zu erhalten, sowie
                    das Recht auf Berichtigung, Löschung, Einschränkung der Verarbeitung, Datenübertragbarkeit und
                    Widerspruch gegen die Verarbeitung. Zur Ausübung dieser Rechte können Sie sich an die im Impressum
                    genannte Kontaktadresse wenden.
                </Text>

                <Heading as="h2" size="lg" mt={6} mb={2}>
                    Beschwerderecht bei einer Aufsichtsbehörde
                </Heading>
                <Text mb={4}>
                    Wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten gegen die DSGVO
                    verstößt, haben Sie das Recht, bei einer zuständigen Datenschutzaufsichtsbehörde Beschwerde
                    einzureichen.
                </Text>

                <Heading as="h2" size="lg" mt={6} mb={2}>
                    Änderungen der Datenschutzerklärung
                </Heading>
                <Text mb={4}>
                    Wir behalten uns vor, diese Datenschutzerklärung bei Bedarf anzupassen, um etwaigen Änderungen
                    rechtlicher Vorgaben oder unseres Onlineangebots gerecht zu werden. Die jeweils aktuelle Version
                    ist über unsere Plattform abrufbar.
                </Text>

                <Text mb={4}>
                    Mit der Nutzung der classrate Plattform erklären Sie sich mit den in dieser Datenschutzerklärung
                    beschriebenen Verarbeitungen einverstanden.
                </Text>
            </Box>
            <BottomBar />
        </Box>
    );
};

export default PrivacyPolicy;
