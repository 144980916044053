import React from "react";
import { useMediaQuery } from "@chakra-ui/react";
import AuthLayout from "../components/layouts/AuthLayout";
import MobileAuthLayout from "../components/layouts/MobileAuthLayout";
import SignInComponent from "../components/SignInComponent";
import { Helmet } from "react-helmet-async";
function SignIn() {
  const renderHelmet = () => {

    return (
      <Helmet>
        <title>{`Sign In - Classrate`}</title>
        <meta
          name="description"
          content={`Sign in and discover how your fellow students think about the courses in your study program. Ratings, comments and more!`}
        />
        <meta
          name="keywords"
          content={`sign in, classrate, course ratings, university courses,`}
        />
      </Helmet>
    );
  };
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  return (isLargerThan768 ? (
    <> {renderHelmet()}
      <AuthLayout>
        <SignInComponent />
      </AuthLayout>
    </>
  ) : (
    <> {renderHelmet()}
      <MobileAuthLayout>
        <SignInComponent />
      </MobileAuthLayout>
    </>)

  );
}

export default SignIn;