import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Spinner,
  Tooltip,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Text,
  useMediaQuery,
  useColorModeValue,
  Icon,
  useColorMode,
  Image,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";

import {
  EmailIcon,
  SearchIcon,
  MoonIcon,
  SunIcon,
  CheckIcon,
  BellIcon,
} from "@chakra-ui/icons";
import { debounce } from "lodash";
import { FaBell } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import AuthContext from "../AuthContext";
import request from "../api";
import ContactFormComponent from "./ContactFormComponent";
import TutorialPopover from "./TutorialPopover";
import { useTranslation } from "react-i18next";
import { CourseResult } from "./CourseResult";
import UserAvatar from "./UserAvatar";

import { color } from "chart.js/helpers";

const LanguageMenu = ({ changeLanguage, t, i18n }) => {
  return (
    <Tooltip label={t("change_language")}>
      <Image
        src={i18n.language === "de" ? "/de.svg" : "/gb.svg"}
        alt={i18n.language === "de" ? "DE": "EN"}
        // width="25px"
        boxSize={5}
        borderRadius={"md"}
        mr={7}
        cursor={"pointer"}
        onClick={changeLanguage}
      />
    </Tooltip>
  );
};

const ChangeColorMode = ({
  isSignedIn,
  t,
  userColorMode,
  useColorModeValue,
  changeColorMode,
}) => {
  return (
    <Menu>
      <Tooltip label={t("change_color_mode")}>
        <MenuButton mr="5">
          <Flex position="relative">
            {useColorModeValue(
              <MoonIcon boxSize={5} />,
              <SunIcon boxSize={5} />
            )}
          </Flex>
        </MenuButton>
      </Tooltip>
      {/* <Spacer /> */}
      <MenuList p="7px" border="none">
        <Text fontSize="md">{t("set_color_mode")}</Text>
        <Divider mb="0px" />
        <Flex flexDirection="column">
          <MenuItem
            onClick={() => {
              changeColorMode("light");
            }}
          >
            <Text fontSize="md">{t("light_mode")}</Text>
            {userColorMode === "light" && (
              <Icon ml={2} color="green.500" as={CheckIcon} />
            )}
          </MenuItem>
          <MenuItem
            onClick={() => {
              changeColorMode("dark");
            }}
          >
            <Text fontSize="md">{t("dark_mode")}</Text>
            {userColorMode === "dark" && (
              <Icon ml={2} color="green.500" as={CheckIcon} />
            )}
          </MenuItem>
          {isSignedIn && (
            <MenuItem
              onClick={() => {
                changeColorMode("system");
              }}
            >
              <Text fontSize="md">{t("system_mode")}</Text>
              {userColorMode === "system" && (
                <Icon ml={2} color="green.500" as={CheckIcon} />
              )}
            </MenuItem>
          )}
        </Flex>
      </MenuList>
    </Menu>
  );
};

const Navbar = ({ updateUser }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedResultIndex, setSelectedResultIndex] = useState(-1);
  const [showSearchResults, setShowSearchResults] = useState(false);
  // const { handleSignOut } = useContext(AuthContext);
  const { isSignedIn, role, handleSignOut } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [userColorMode, setUserColorMode] = useState("");
  const { colorMode, toggleColorMode } = useColorMode();
  const { t, i18n } = useTranslation();
  const [isLoggedOutDueToInactivity, setIsLoggedOutDueToInactivity] = useState(false);


  async function changeLanguage() {
    const newLanguage = i18n.language == "de" ? "en" : "de";
    i18n.changeLanguage(newLanguage);
    if (isSignedIn) {
      try {
        await request(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/change-language`,
          "PUT",
          {
            language: newLanguage,
          }
        );
      } catch (error) {
        console.error("Error updating user language:", error);
      }
    }
  }
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const q = params.get("q");

    if (q !== null && q !== undefined && q !== "") {
      setSearchQuery(q);
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const q = params.get("q");
    if (q === null || q === undefined || q === "") {
      setSearchQuery("");
    }
  }, [window.location.search]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const q = params.get("q");
    if (q === searchQuery && searchQuery !== "") {
      setShowSearchResults(false);
    } else {
      setShowSearchResults(true);
    }
  }, [searchQuery]);

  const renderNotificationIcon = (type) => {
    switch (type.toUpperCase()) {
      case "REPLY":
        return "💬";
      case "OFFER":
        return "💼";
      case "REPORT":
        return "🚩";
      case "EMPLOYMENT":
        return "🤝";
      default:
        return null;
    }
  };

  const [notifications, setNotifications] = useState([]);

  const [unreadCount, setUnreadCount] = useState(
    notifications.filter((notification) => !notification.read).length
  );
  const [showNotifications, setShowNotifications] = useState(false);

  useEffect(() => {
    if (isSignedIn) fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/notifications`,
        "GET"
      );
      setNotifications(response);
      const unreadNotifications = response.filter(
        (notification) => !notification.read
      );
      setUnreadCount(unreadNotifications.length);
    } catch (error) {
      console.error("Failed to fetch notifications", error);
    }
  };

  const handleNotificationClick = (notification) => {
    if (notification.link) {
      navigate(notification.link);
    }
  };

  const markNotificationAsRead = async (notificationId) => {
    try {
      const response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/notifications/${notificationId}`,
        "PATCH"
      );
      const updatedNotification = response;
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification._id === updatedNotification._id
            ? updatedNotification
            : notification
        )
      );
    } catch (error) {
      console.error("Failed to mark notification as read", error);
    }
  };

  const toggleNotifications = () => {
    setShowNotifications((prevShowNotifications) => !prevShowNotifications);
    setUnreadCount(0);

    if (showNotifications) {
      notifications.forEach((notification) => {
        if (!notification.read) {
          markNotificationAsRead(notification._id);
        }
      });
    }
  };

  const updateUserColorMode = async (mode) => {
    try {
      await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/change-color-mode`,
        "PUT",
        {
          colorMode: mode,
        }
      );
    } catch (error) {
      console.error("Error updating user color mode:", error);
    }
  };

  const changeColorMode = (mode) => {
    if (mode !== colorMode) {
      if (isSignedIn && userColorMode !== mode) {
        updateUserColorMode(mode);
      }

      if (mode === "system") {
        localStorage.removeItem("chakra-ui-color-mode");
        window.location.reload();
      } else {
        toggleColorMode();
      }
    }
    setUserColorMode(mode);
  };



  const fetchUser = async () => {
    // if (!isSignedIn) return;
    try {
      const response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/user-profile`,
        "GET"
      );
      setUser(response);
      setUserColorMode(response.colorMode);

    } catch (error) {
      // if error is 401 Your session has expired then refresh page and set isLoggedOutDueToInactivity to true
      // message needs to be error: "Your session has expired"
      if (error.error === "Your session has expired") {
        setIsLoggedOutDueToInactivity(true);
        console.log("Session expired");
        // toast({
        //   title: t("session_expired"),
        //   description: t("session_expired_description"),
        //   status: "info",
        //   duration: 4000,
        //   isClosable: true,
        // });
      } else {
        console.log("Error fetching user:", error);
      }
      handleSignOut(true);

      console.error("Error fetching user:", error);
    }
  };

  useEffect(() => {
    if (isSignedIn) fetchUser();
  }, [updateUser, isSignedIn]);

  useEffect(() => {
    if (user && user.avatar === null && isSignedIn) {
      const interval = setInterval(() => {
        fetchUser();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [user, isSignedIn]);

  const searchInputRef = useRef(null);
  const popoverRef = useRef(null);
  const navigate = useNavigate();

  const getTotalCoursesCountBeforeCategory = (category) => {
    let totalCount = 0;
    for (const [key, courses] of Object.entries(searchResults)) {
      if (key === category) {
        return totalCount;
      }
      totalCount += courses.length;
    }
    return totalCount;
  };

  const toast = useToast();

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        if (searchQuery.trim() !== "") {
          setIsLoading(true);
          const response = await request(
            `${process.env.REACT_APP_BACKEND_URL}/api/v1/home/search?q=${searchQuery}&limit=20`,
            "GET"
          );

          setSearchResults(response);

          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };

    const debouncedFetchSearchResults = debounce(fetchSearchResults, 200);

    if (searchQuery.trim() !== "" && searchQuery.length >= 3) {
      debouncedFetchSearchResults();
    } else {
      setSearchResults(null);
    }

    return () => {
      debouncedFetchSearchResults.cancel();
    };
  }, [searchQuery]);

  const handleSearchInputClick = () => {
    popoverRef.current && popoverRef.current.onOpen();
  };

  const handleResultKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();
      setSelectedResultIndex((prevIndex) =>
        prevIndex > -1 ? prevIndex - 1 : prevIndex
      );
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      setSelectedResultIndex((prevIndex) =>
        prevIndex < getTotalSearchResultsLength() - 1
          ? prevIndex + 1
          : prevIndex
      );
    } else if (event.key === "Enter") {
      event.preventDefault();
      if (selectedResultIndex !== -1) {
        const result = getSelectedSearchResult();

        if (result) {
          const courseID = result.course._id;
          navigate(`/course/${courseID}`);
          setShowSearchResults(false);
          setSearchQuery("");
        }
      } else {
        navigate(`/courses?q=${searchQuery}&sortBy=name&sortOrder=asc&page=1`);
        setShowSearchResults(false);
      }
    }
  };

  const getTotalSearchResultsLength = () => {
    if (!searchResults) return 0;

    let totalLength = 0;
    Object.values(searchResults).forEach((arr) => {
      totalLength += arr.length;
    });

    return totalLength;
  };

  const getSelectedSearchResult = () => {
    if (!searchResults) return null;

    let currentIndex = 0;
    for (const [category, courses] of Object.entries(searchResults)) {
      if (currentIndex + courses.length > selectedResultIndex) {
        const selectedCourse = courses[selectedResultIndex - currentIndex];
        return { category, course: selectedCourse };
      }
      currentIndex += courses.length;
    }

    return null;
  };



  return (
    <Flex
      alignItems="center"
      bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
      p={isLargerThan768 ? 4 : 2}
      position="fixed"
      top={0}
      left={0}
      right={0}
      zIndex={100}
      borderColor={"gray.300"}
      borderBottomWidth={1}
      height="80px"
      maxW={"100vw"}
      justifyContent={!isLargerThan768 && "space-between"}
    >
      <Box onClick={() => navigate("/")} cursor={"pointer"} maxW={"20%"}>
        {isLargerThan768 ? (
          <img
            src={useColorModeValue("/logo_light.svg", "/logo_dark.svg")}
            alt="Logo"
            width="250px"
          />
        ) : (
          <img src={useColorModeValue("/icon_light.svg", "/icon_dark.svg")} alt="Logo" width={"80%"} />
        )}
      </Box>
      {isLargerThan768 && <Spacer />}
      {isLargerThan768 ? (
        <>
          <Box w="40%" position="relative">
            <TutorialPopover
              childComponent={
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.500" />
                  </InputLeftElement>
                  <Input
                    _hover={{
                      borderColor: "gray.500",
                      borderWidth: 1.5,
                    }}
                    borderColor={"gray.300"}
                    placeholder={t("search_for_courses")}
                    _placeholder={{ color: "gray.500" }}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onClick={handleSearchInputClick}
                    onKeyDown={handleResultKeyDown}
                    ref={searchInputRef}
                    focusBorderColor="main.primary"
                  />
                  {/* add command k shortcut symbol*/}
                  {isLoading && (
                    <Box
                      position="absolute"
                      top="50%"
                      right={4}
                      transform="translateY(-50%)"
                    >
                      <Spinner size="sm" color="gray.500" />
                    </Box>
                  )}
                </InputGroup>
              }
              stepNumber={1}
            />
            {searchResults && showSearchResults && (
              <Popover
                placement="bottom"
                closeOnBlur={false}
                isOpen={
                  searchResults !== null &&
                  Object.values(searchResults).some((arr) => arr.length > 0)
                }
                initialFocusRef={searchInputRef}
                ref={popoverRef}
                isLazy
              >
                <PopoverTrigger>
                  <Input
                    pointerEvents="none"
                    opacity={0}
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    cursor="pointer"
                  />
                </PopoverTrigger>
                <PopoverContent
                  p={4}
                  bg={useColorModeValue(
                    "main.elementBgLight",
                    "main.elementBgDark"
                  )}
                  borderWidth={1}
                  borderColor={"gray.500"}
                  borderRadius={"md"}
                  width={searchInputRef.current?.offsetWidth}
                  maxHeight="400px"
                  overflowY="auto"
                >
                  <PopoverArrow />
                  {/* <PopoverCloseButton /> */}
                  <PopoverBody>
                    <>
                      <CourseResult
                        key={"search-for"}
                        course={{
                          _id: "search-for",
                          name: { en: searchQuery },
                          courseCode: "search-for",
                        }}
                        isSelected={selectedResultIndex === -1}
                        setShowSearchResults={setShowSearchResults}
                        setSearchQuery={setSearchQuery}
                        searchQuery={searchQuery}
                      />
                      <Divider my={2} bg={"gray.300"} />
                    </>
                    {Object.entries(searchResults).map(
                      ([category, courses]) => {
                        if (courses.length > 0) {
                          return (
                            <>
                              <Box bg={"gray.200"} borderRadius="lg">
                                <Text fontWeight="bold" color="gray.900" m={2}>
                                  {(
                                    category.charAt(0).toUpperCase() +
                                    category.slice(1)
                                  ).replace(
                                    "CourseFromOtherPrograms",
                                    "Other Programs"
                                  )}
                                  :
                                </Text>
                              </Box>
                              {courses.map((course, index) => (
                                <>
                                  <CourseResult
                                    key={course._id}
                                    course={course}
                                    isFromOtherProgram={
                                      category === "courseFromOtherPrograms"
                                    }
                                    isSelected={
                                      selectedResultIndex ===
                                      index +
                                      getTotalCoursesCountBeforeCategory(
                                        category
                                      )
                                    }
                                    setShowSearchResults={setShowSearchResults}
                                    setSearchQuery={setSearchQuery}
                                    searchQuery={searchQuery}
                                  />
                                  <Divider my={2} bg={"gray.300"} />
                                </>
                              ))}
                            </>
                          );
                        }
                        return null;
                      }
                    )}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            )}
          </Box>
          {/* <Spacer /> */}
          <TutorialPopover
            childComponent={
              <Button
                variant="ghost"
                mr={2}
                onClick={() => {
                  navigate("/courses?sortBy=averageRating&sortOrder=desc");
                }}
              >
                {t("explore_all_courses")}
              </Button>
            }
            stepNumber={2}
          />
        </>
      ) : (
        <TutorialPopover
          childComponent={
            <IconButton
              icon={<SearchIcon />}
              size="lg"
              mr={2}
              color="bla"
              variant={"ghost"}
              onClick={() => {
                navigate("/courses?sortBy=averageRating&sortOrder=desc");
              }}
            />}
          stepNumber={2}
        />
      )}
      {isLargerThan768 && (
        <>
          <Spacer />
          <LanguageMenu changeLanguage={changeLanguage} t={t} i18n={i18n} />
          <ChangeColorMode
            isSignedIn={isSignedIn}
            t={t}
            userColorMode={userColorMode}
            useColorModeValue={useColorModeValue}
            changeColorMode={changeColorMode}
          />
        </>
      )}
      {isSignedIn ? (
        <>
          <Menu>
            <MenuButton mr="5" onClick={toggleNotifications}>
              <Flex position="relative">
                <BellIcon
                  boxSize={6}
                  // fontWeight="700"
                  cursor={"pointer"}
                  onClick={toggleNotifications}
                />
                {unreadCount > 0 && (
                  <Badge
                    // colorScheme="red"
                    bg="red.500"
                    borderRadius="full"
                    boxSize="18px"
                    fontSize="xs"
                    position="absolute"
                    top="-4px"
                    color={"white"}
                    right="-4px"
                  >
                    {unreadCount}
                  </Badge>
                )}
              </Flex>
            </MenuButton>
            {toggleNotifications && (
              <MenuList p="0px" mt="10px" border="none">
                {/* Render notifications */}
                {notifications.map((notification) => (
                  <MenuItem
                    key={notification.id}
                    onClick={() => handleNotificationClick(notification)}
                    isDisabled={notification.link === null}
                  >
                    {renderNotificationIcon(notification.type)}
                    <Text ml="5px" fontSize="md">
                      {notification.content}
                    </Text>
                    {notification.read ? null : (
                      <Badge ml="5px" colorScheme="green">
                        {t("new")}
                      </Badge>
                    )}
                  </MenuItem>
                ))}
              </MenuList>
            )}
          </Menu>
          {/* {!isLargerThan768 && <Spacer />} */}
          <TutorialPopover
            childComponent={
              <Badge
                mr={4}
                fontSize="l"
                borderRadius="md"
                cursor="pointer"
                onClick={() => navigate("/leaderboard")}
              >
                <Tooltip label={t("karma_points")} hasArrow>
                  <span>🔥 {user?.karmaPoints}</span>
                </Tooltip>
              </Badge>
            }
            stepNumber={3}
          />
          <Menu>

            <TutorialPopover
              childComponent={
                <MenuButton p="0px" pr={2}>
                  <UserAvatar size={"md"} user={user} onClick={() => { }} />

                </MenuButton>}
              stepNumber={4}

            />

            <MenuList p="0px" mt="10px" border="none" zIndex={110}>
              <Text
                ps="20px"
                pt="16px"
                pb="10px"
                w="100%"
                fontSize="md"
                fontWeight="700"
              >
                {t("hey_user", { username: user?.username })}
              </Text>

              <Divider mb="0px" />
              <Flex w="100%" mb="0px">
                <Text
                  ps="20px"
                  pt="10px"
                  pb="10px"
                  w="100%"
                  fontSize="md"
                  fontWeight="700"
                >
                  {user?.rank.currentRank.name} {user?.rank.currentRank.emoji}
                </Text>
              </Flex>
              <Divider mb="0px" />
              <Flex flexDirection="column" p="10px">
                {!isLargerThan768 && (
                  <HStack ml={4}>
                    <ChangeColorMode
                      isSignedIn={isSignedIn}
                      t={t}
                      userColorMode={userColorMode}
                      useColorModeValue={useColorModeValue}
                      changeColorMode={changeColorMode}
                    />
                    <LanguageMenu
                      changeLanguage={changeLanguage}
                      t={t}
                      i18n={i18n}
                    />
                  </HStack>
                )}

                <MenuItem
                  onClick={() => {
                    navigate(`/profile`);
                  }}
                >
                  <Text fontSize="sm">{t("my_profile")}</Text>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  <Text fontSize="sm">{t("contact_us")}</Text>
                </MenuItem>
                <ContactFormComponent isOpen={isOpen} setIsOpen={setIsOpen} />
                {user?.role === "ADMIN" && (
                  <MenuItem
                    onClick={() => {
                      navigate(`/admin`);
                    }}
                  >
                    <Text fontSize="sm">{t("admin_panel")}</Text>
                  </MenuItem>
                )}
                <MenuItem
                  color="red.400"
                  onClick={() => {
                    handleSignOut(false);
                  }}
                >
                  <Text fontSize="sm">{t("log_out")}</Text>
                </MenuItem>
              </Flex>
            </MenuList>
          </Menu>
        </>
      ) : (
        <>
          {!isLargerThan768 && <Spacer />}
          <Button
            borderColor="main.primary"
            variant="ghost"
            mr={2}
            color="main.primary"
            onClick={() => {
              navigate(
                `/sign-in?redirect=${encodeURIComponent(
                  window.location.pathname
                )}`
              );
            }}
          >
            {t("sign_in")}
          </Button>
          <Button
            bg="main.primary"
            color={"white"}
            variant={"solid"}
            _hover={{ bg: "main.secondary" }}
            onClick={() => {
              navigate("/sign-up");
            }}
          >
            {t("sign_up")}
          </Button>
        </>
      )}
    </Flex>
  );
};

export default Navbar;
