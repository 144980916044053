import React from "react";
import { useMediaQuery } from "@chakra-ui/react";
import AuthLayout from "../components/layouts/AuthLayout";
import MobileAuthLayout from "../components/layouts/MobileAuthLayout";
import SignUpComponent from "../components/SignUpComponent";
import { Helmet } from "react-helmet-async";

function SignUp() {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

    const renderHelmet = () => {
  
      return (
        <Helmet>
          <title>{`Sign Up - Classrate`}</title>
          <meta
            name="description"
            content={`Register and discover how your fellow students think about the courses in your study program. Ratings, comments and more!`}
          />
          <meta
            name="keywords"
            content={`sign up, classrate, course ratings, university courses,`}
          />
        </Helmet>
      );
    };
  return (isLargerThan768 ? (
    <> {renderHelmet()}
    <AuthLayout>
      <SignUpComponent />
    </AuthLayout>
    </>
  ): (
    <> {renderHelmet()}
    <MobileAuthLayout>
      <SignUpComponent />
    </MobileAuthLayout>
    </>
    ));
}

export default SignUp;
