import {
  Box,
  Button,
  Flex,
  Text,
  Badge,
  Avatar,
  HStack,
  IconButton,
  Tooltip,
  Textarea,
  useColorModeValue,
  Icon,
  Tag,
  useMediaQuery,
  Spacer,
  Select
} from "@chakra-ui/react";
import { StarIcon, ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { IoMdThumbsUp, IoMdThumbsDown } from "react-icons/io";
import {
  useState, useEffect
} from "react";
import { useToast } from "@chakra-ui/react";
import DeleteEditButtons from "./DeleteEditButtons";
import LikeDislikeButton from "./LikeDislikeButton";
import DateEditedHStack from "./DateEditedHStack";
import ReportButton from "./ReportButton";
import { editElement } from "./DeleteEditButtons";
import { useTranslation } from "react-i18next";
import request from "../api";

export default function Comment({
  id,
  username,
  karma,
  avatarID,
  text,
  date,
  ratingStars,
  wouldRecommend,
  edited,
  authorDeleted,
  postedBy,
  attendedInSemester,
  likes,
  dislikes,
  isLikedByUser,
  isDislikedByUser,
  isAuthor,
  courseID,
  fetchComments,
  fetchCourseData,
  isAdmin,
  updateUser,
  setUpdateUser,
  translation,
}) {
  const fetchedText = text;
  const toast = useToast();
  const [showFullReview, setShowFullReview] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [commentText, setCommentText] = useState(text);
  const [isEditing, setIsEditing] = useState(false);
  const [editedComment, setEditedComment] = useState(text);
  const { t, i18n } = useTranslation();
  // const [showTranslation, setShowTranslation] = useState(i18n.language === "en"); // default to show translation if not in English for english users
  const [showTranslation, setShowTranslation] = useState(false);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [attendedInSelected, setAttendedInSelected] = useState("");
  const [semesterOptions, setSemesterOptions] = useState([]);

  useEffect(() => {
    setSemesterOptions(generateSemesters());
    setAttendedInSelected(attendedInSemester?.term + " " + attendedInSemester?.year);
  }, []);
  const toggleTranslation = () => {
    setShowTranslation(!showTranslation);
  };

  const toggleReadMore = () => {
    setShowFullReview(!showFullReview);
  };

  const generateSemesters = () => {
    const options = [];
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed

    // Determine the current semester
    const isSummerSemester = currentMonth >= 4 && currentMonth <= 9;
    let year = currentYear
    let semesterType = isSummerSemester ? "summer" : "winter";

    // Generate the current and last 5 semesters
    for (let i = 0; i < 6; i++) {
      if (semesterType === "summer") {
        options.push({
          term: `summer_semester`,
          year: `${year}`,
        });
        semesterType = "winter";
      } else {
        options.push({
          term: `winter_semester`,
          year: `${year - 1}/${(year).toString().slice(-2)}`,
        });
        semesterType = "summer";
        year -= 1; // Move to the previous year
      }
    }

    return options;
  };

  const handleCommentEdit = async () => {
    text = editedComment;
    if (text.trim() == fetchedText.trim() && attendedInSelected == attendedInSemester.term + " " + attendedInSemester.year) return;

    if (text.trim() == "") {
      toast({
        title: "Empty Comment",
        description:
          "If you want to delete the comment, hit the delete button.",
        status: "info",
        duration: 2000,
        isClosable: true,
      });
      setEditedComment(fetchedText);
      text = fetchedText;
      setIsEditing(false);
      return;
    }

    const success = await editElement(
      text,
      courseID + "/comments/" + id,
      "comment",
      toast,
      attendedInSelected
    );
    if (!success) {
      text = fetchedText;
      setEditedComment(fetchedText);
    } else {
      
      fetchComments();
      try {
        if (text.trim() !== fetchedText.trim()) {
          await request(
            `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${courseID}/translate`,
            "POST",
            {
              text: text.trim(),
              elementID: id,
              elementType: "comment",
            }

          );
        }
      } catch (error) {
        console.error("Translation failed: ", error);
      }
    }
    setIsEditing(false);
  };

  return (
    <Flex
      alignItems="center"
      bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
      borderRadius={"lg"}
      shadow="md"
      p={3}
      mb={2}
    >
      <Avatar
        name={authorDeleted ? null : username}
        src={
          !authorDeleted && avatarID
            ? `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/user/avatar/${avatarID}`
            : null
        }
        size="md"
        mr={2}
      />
      <Box flex="1">
        <HStack spacing={1}>
          <Flex>
            <Text fontWeight={!authorDeleted && "bold"}>
              {authorDeleted ? t("deleted") : username}
            </Text>
            {isAuthor && !authorDeleted && <Text ml={1}>{"(" + t("you") + ")"} </Text>}
          </Flex>
          {!authorDeleted && (
            <Badge colorScheme="light-grey" fontSize={"sm"} >
              {t("karma_emoji")} {karma}
            </Badge>
          )}

          {isAuthor || isAdmin ? (
            <DeleteEditButtons
              typeName={"rating"}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              route={`${courseID}/comments/${id}`}
              fetchData={fetchComments}
              fetchCourseData={fetchCourseData}
              isAuthor={isAuthor}
              updateUser={updateUser}
              setUpdateUser={setUpdateUser}
            />
          ) : (
            <ReportButton
              courseID={courseID}
              elementType={"comment"}
              elementId={id}
            />
          )}
          <Box flex="1" />
          {isLargerThan768 ? <HStack alignItems="center" spacing={0.5}>
            <Icon mr={2} as={wouldRecommend ? IoMdThumbsUp : IoMdThumbsDown} color={wouldRecommend ? "green.300" : "red.300"} boxSize={5} />
            {Array.from({ length: 5 }, (_, index) => (
              <Icon
                as={StarIcon}
                key={index}
                alignSelf={"center"}
                color={index < ratingStars ? "yellow.300" : "gray.300"}
              />
            ))}
          </HStack> : null}
        </HStack>
        {!isLargerThan768 && <HStack alignItems="center" spacing={0.5}>
          {attendedInSemester?.term && attendedInSemester?.year && <Tag size="sm" my={0} variant="solid" colorScheme="gray">
            {t(attendedInSemester.term + "_short") + " " + attendedInSemester.year}
          </Tag>}
          <Spacer />
          <Icon mr={1} as={wouldRecommend ? IoMdThumbsUp : IoMdThumbsDown} color={wouldRecommend ? "green.300" : "red.300"} boxSize={5} />
          {Array.from({ length: 5 }, (_, index) => (
            <Icon
              as={StarIcon}
              key={index}
              alignSelf={"center"}
              color={index < ratingStars ? "yellow.300" : "gray.300"}
            />
          ))}
        </HStack>}
        <Flex alignItems="center">
          <DateEditedHStack date={date} edited={edited}></DateEditedHStack>
          <Box flex="1" />
          {attendedInSemester?.term && attendedInSemester?.year && isLargerThan768 &&
          <>
            {!isEditing ?
            <Tooltip
              label={t("attended_in")}
              placement="top"
              hasArrow
              aria-label="attended in">
              <Tag size="sm" my={2} variant="solid" colorScheme="gray">
                {t(attendedInSemester.term + "_short") + " " + attendedInSemester.year}
              </Tag>
            </Tooltip> :
            <Select
              value={attendedInSelected}
              onChange={(e) => setAttendedInSelected(e.target.value)}
              // maxW={"55%"}
              size="sm"
              pb={1}
              width={`${attendedInSelected.length + 3}ch`}
            >
              {semesterOptions.map((option, index) => (
                <option key={index} value={option.term + " " + option.year}>
                  {t(option.term) + " " + option.year}
                </option>
              ))}
            </Select>}
          </>
          }


        </Flex>
        {
          isEditing ? (
            <Textarea
              value={editedComment}
              onChange={(e) => setEditedComment(e.target.value)}
              mb={2}
              resize="none"
            />
          ) : (
            <Text
              as={
                showTranslation && translation.language === i18n.language
                  ? "i"
                  : null
              }
            >
              {showFullReview ? (
                <>
                  {showTranslation && translation.language === i18n.language
                    ? translation.text
                    : text
                  }
                  <Button
                    variant="link"
                    color="main.primary"
                    fontWeight="bold"
                    size="sm"
                    ml={2}
                    onClick={toggleReadMore}
                  >
                    {t("hide")}
                  </Button>
                  {translation && translation.language === i18n.language && (
                    <Button
                      variant="link"
                      color="main.primary"
                      size="sm"
                      ml={2}
                      onClick={toggleTranslation}
                    >
                      {showTranslation ? t("show_original") : t("translate")}
                    </Button>
                  )}
                </>
              ) : (
                <>
                  {(showTranslation && translation.language === i18n.language
                    ? translation.text
                    : text
                  ).length > 200
                    ? `${(showTranslation &&
                      translation.language === i18n.language
                      ? translation.text
                      : text
                    ).slice(0, 200)}...`
                    : showTranslation && translation.language === i18n.language
                      ? translation.text
                      : text}
                  {(showTranslation && translation.language === i18n.language
                    ? translation.text
                    : text
                  ).length > 200 && (
                      <Button
                        variant="link"
                        color="main.primary"
                        fontWeight="bold"
                        size="sm"
                        ml={2}
                        onClick={toggleReadMore}
                      >
                        {t("read_more")}
                      </Button>
                    )}
                  {translation && translation.language === i18n.language && (
                    <Button
                      variant="link"
                      color="main.primary"
                      size="sm"
                      ml={2}
                      onClick={toggleTranslation}
                    >
                      {showTranslation ? t("show_original") : t("translate")}
                    </Button>
                  )}
                </>
              )}
            </Text>
          )
        }
        <Box d="flex" justifyContent="flex-end" alignSelf={"right"}>
          {isEditing ? (
            <HStack spacing={2}>
              <Box flex="1" />
              <Button
                colorScheme="gray"
                size="sm"
                onClick={() => {
                  setEditedComment(fetchedText);
                  setIsEditing(false);
                }}
                mr={2}
              >
                {t("cancel")}
              </Button>

              <Button
              isDisabled={editedComment.trim() == fetchedText.trim() && attendedInSelected == attendedInSemester.term + " " + attendedInSemester.year}
                color="white"
                bg="main.primary"
                _hover={{ bg: "main.secondary" }}
                size="sm"
                onClick={handleCommentEdit}
                justifyContent={"flex-end"}
                
              >
                {t("save")}
              </Button>
            </HStack>
          ) : null}
        </Box>
      </Box >

      <LikeDislikeButton
        isLikedByUser={isLikedByUser}
        isDislikedByUser={isDislikedByUser}
        likes={likes}
        dislikes={dislikes}
        courseID={courseID}
        route={courseID + "/comments/" + id}
        fetchData={fetchComments}
        updateUser={updateUser}
        setUpdateUser={setUpdateUser}
      />
    </Flex >
  );
}
