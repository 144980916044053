import {
    Badge,
    Box,
    Button,
    ButtonGroup,
    Flex,
    Heading,
    Icon,
    Input,
    Select, Stack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text, Tfoot,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import UserAvatar from './UserAvatar';
import StatCard from "./StatCard";

export default function UserTable({ admin, users, handleChangeRole, handleBan, handleUnban, stats }) {


    const [displayedUsers, setDisplayedUsers] = useState(10);
    const [totalUsersCount, setTotalUsersCount] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({
        key: 'username',
        direction: 'asc',
    });

    useEffect(() => {
        setTotalUsersCount(users.length)
    }, [admin, users, stats])

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSort = (key) => {
        if (sortConfig.key === key) {
            setSortConfig({
                ...sortConfig,
                direction: sortConfig.direction === 'asc' ? 'desc' : 'asc',
            });
        } else {
            setSortConfig({
                key,
                direction: 'asc',
            });
        }
    };

    const loadMoreUsers = () => {
        if (displayedUsers + 10 <= totalUsersCount) {
            setDisplayedUsers(displayedUsers + 10);
        } else {
            setDisplayedUsers(totalUsersCount);
        }
    };

    const sortedUsers = users.sort((a, b) => {
        if (sortConfig.direction === 'asc') {
            if (sortConfig.key === 'ratings' || sortConfig.key === 'questions' || sortConfig.key === 'answers') {
                return a[sortConfig.key] - b[sortConfig.key];
            } else {
                return a[sortConfig.key].localeCompare(b[sortConfig.key]);
            }
        } else {
            if (sortConfig.key === 'ratings' || sortConfig.key === 'questions' || sortConfig.key === 'answers') {
                return b[sortConfig.key] - a[sortConfig.key];
            } else {
                return b[sortConfig.key].localeCompare(a[sortConfig.key]);
            }
        }
    });

    const filteredUsers = sortedUsers.filter(
        (user) =>
            user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.email.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const displayedFilteredUsers = filteredUsers.slice(0, displayedUsers);


    return (
        <Box p={4}>
            <Flex justifyContent="center" mb={4}>
                <Stack direction="row" spacing={4}>
                    <StatCard label="Users" value={stats.users} />
                    <StatCard label="Ratings" value={stats.ratings} />
                    <StatCard label="Questions" value={stats.questions} />
                    <StatCard label="Answers" value={stats.answers} />
                </Stack>
            </Flex>
            <TableContainer>
                <Input
                    placeholder="Search by username or email"
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    mb={4}
                />
                <Table variant='simple' colorScheme='blue'>
                    <Thead>
                        <Tr>
                            <Th onClick={() => handleSort('username')} cursor="pointer">
                                <Flex justifyContent="space-between" alignItems="center">
                                    <Flex direction="column">
                                        <Text>Username</Text>
                                    </Flex>
                                    {sortConfig.key === 'username' ? (
                                        <Flex direction="column">
                                            <Icon as={ChevronUpIcon}
                                                color={sortConfig.direction === 'asc' ? 'main.primary' : 'gray.400'} />
                                            <Icon as={ChevronDownIcon}
                                                color={sortConfig.direction === 'desc' ? 'main.primary' : 'gray.400'} />
                                        </Flex>
                                    ) : (<Flex direction="column">
                                        <Icon as={ChevronUpIcon}
                                            color={'gray.400'} />
                                        <Icon as={ChevronDownIcon}
                                            color={'gray.400'} />
                                    </Flex>)
                                    }
                                </Flex>
                            </Th>
                            <Th onClick={() => handleSort('email')} cursor="pointer">
                                <Flex justifyContent="space-between" alignItems="center">
                                    <Flex direction="column">
                                        <Text>Email</Text>
                                    </Flex>
                                    {sortConfig.key === 'email' ? (
                                        <Flex direction="column">
                                            <Icon as={ChevronUpIcon}
                                                color={sortConfig.direction === 'asc' ? 'main.primary' : 'gray.400'} />
                                            <Icon as={ChevronDownIcon}
                                                color={sortConfig.direction === 'desc' ? 'main.primary' : 'gray.400'} />
                                        </Flex>
                                    ) : (<Flex direction="column">
                                        <Icon as={ChevronUpIcon}
                                            color={'gray.400'} />
                                        <Icon as={ChevronDownIcon}
                                            color={'gray.400'} />
                                    </Flex>)
                                    }
                                </Flex>
                            </Th>
                            <Th onClick={() => handleSort('role')} cursor="pointer">
                                <Flex justifyContent="space-between" alignItems="center">
                                    <Flex direction="column">
                                        <Text>Role</Text>
                                    </Flex>
                                    {sortConfig.key === 'role' ? (
                                        <Flex direction="column">
                                            <Icon as={ChevronUpIcon}
                                                color={sortConfig.direction === 'asc' ? 'main.primary' : 'gray.400'} />
                                            <Icon as={ChevronDownIcon}
                                                color={sortConfig.direction === 'desc' ? 'main.primary' : 'gray.400'} />
                                        </Flex>
                                    ) : (<Flex direction="column">
                                        <Icon as={ChevronUpIcon}
                                            color={'gray.400'} />
                                        <Icon as={ChevronDownIcon}
                                            color={'gray.400'} />
                                    </Flex>)
                                    }
                                </Flex>
                            </Th>
                            <Th onClick={() => handleSort('ratings')} cursor="pointer">
                                <Flex justifyContent="space-between" alignItems="center">
                                    <Flex direction="column">
                                        <Text>Ratings</Text>
                                    </Flex>
                                    {sortConfig.key === 'ratings' ? (
                                        <Flex direction="column">
                                            <Icon as={ChevronUpIcon}
                                                color={sortConfig.direction === 'asc' ? 'main.primary' : 'gray.400'} />
                                            <Icon as={ChevronDownIcon}
                                                color={sortConfig.direction === 'desc' ? 'main.primarymary' : 'gray.400'} />
                                        </Flex>
                                    ) : (<Flex direction="column">
                                        <Icon as={ChevronUpIcon}
                                            color={'gray.400'} />
                                        <Icon as={ChevronDownIcon}
                                            color={'gray.400'} />
                                    </Flex>)
                                    }
                                </Flex>
                            </Th>
                            <Th onClick={() => handleSort('questions')} cursor="pointer">
                                <Flex justifyContent="space-between" alignItems="center">
                                    <Flex direction="column">
                                        <Text>Questions</Text>
                                    </Flex>
                                    {sortConfig.key === 'questions' ? (
                                        <Flex direction="column">
                                            <Icon as={ChevronUpIcon}
                                                color={sortConfig.direction === 'asc' ? 'main.primary' : 'gray.400'} />
                                            <Icon as={ChevronDownIcon}
                                                color={sortConfig.direction === 'desc' ? 'main.primarymary' : 'gray.400'} />
                                        </Flex>
                                    ) : (<Flex direction="column">
                                        <Icon as={ChevronUpIcon}
                                            color={'gray.400'} />
                                        <Icon as={ChevronDownIcon}
                                            color={'gray.400'} />
                                    </Flex>)
                                    }
                                </Flex>
                            </Th>
                            <Th onClick={() => handleSort('answers')} cursor="pointer">
                                <Flex justifyContent="space-between" alignItems="center">
                                    <Flex direction="column">
                                        <Text>Answers</Text>
                                    </Flex>
                                    {sortConfig.key === 'answers' ? (
                                        <Flex direction="column">
                                            <Icon as={ChevronUpIcon}
                                                color={sortConfig.direction === 'asc' ? 'main.primary' : 'gray.400'} />
                                            <Icon as={ChevronDownIcon}
                                                color={sortConfig.direction === 'desc' ? 'main.primary' : 'gray.400'} />
                                        </Flex>
                                    ) : (<Flex direction="column">
                                        <Icon as={ChevronUpIcon}
                                            color={'gray.400'} />
                                        <Icon as={ChevronDownIcon}
                                            color={'gray.400'} />
                                    </Flex>)
                                    }
                                </Flex>
                            </Th>
                            <Th>Controls</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {displayedFilteredUsers.map((user) => (
                            <Tr>
                                <Td>
                                    <Flex align="center" spacing={2}>
                                        <UserAvatar user={user} size="sm" />
                                        <Text ml={2}>{user.username}</Text>
                                        {user.userId === admin.id && (
                                            <Badge colorScheme="green" variant="outline" ml={2}>
                                                YOU
                                            </Badge>
                                        )}
                                    </Flex>
                                </Td>
                                <Td>{user.email}</Td>
                                <Td><Select
                                    isDisabled={user.userId === admin.id}
                                    value={user.role}
                                    onChange={(e) => handleChangeRole(e.target.value, user.userId)}>
                                    <option key='STUDENT' value='STUDENT'>STUDENT</option>
                                    <option key='ADMIN' value='ADMIN'>ADMIN</option>
                                    <option key='MODERATOR' value='MODERATOR'>MODERATOR</option>
                                </Select></Td>
                                <Td>{user.ratings}</Td>
                                <Td>{user.questions}</Td>
                                <Td>{user.answers}</Td>
                                <Td>
                                    <ButtonGroup direction='row' spacing={1} align='center'>
                                        {!user.banned &&
                                            <Button isDisabled={user.userId === admin.id} size='xs' colorScheme='red'
                                                onClick={() => handleBan(user.userId)}
                                                width='55px'>BAN</Button>}
                                        {user.banned &&
                                            <Button isDisabled={user.userId === admin.id} size='xs' colorScheme='green'
                                                onClick={() => handleUnban(user.userId)}
                                                width='55px'>UNBAN</Button>}
                                    </ButtonGroup>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                    <Tfoot>
                        <Tr>
                            <Th>Username</Th>
                            <Th>Email</Th>
                            <Th>Role</Th>
                            <Th>Ratings</Th>
                            <Th>Questions</Th>
                            <Th>Answers</Th>
                        </Tr>
                        <Text>Showing {Math.min(displayedUsers, users.length)} of {users.length} users</Text>
                        {users.length > displayedUsers && <Button onClick={loadMoreUsers} color="white"
                            bg="main.primary"
                            _hover={{ bg: 'main.secondary' }} mt={4}>
                            Load More
                        </Button>}
                    </Tfoot>
                </Table>
            </TableContainer>
        </Box>
    )
}