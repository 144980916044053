import { Avatar } from "@chakra-ui/react";
import { useEffect, useState } from "react";

const UserAvatar = ({ user, size, onClick }) => {
  const [currentUser, setCurrentUser] = useState(user);

  useEffect(() => {
    setCurrentUser(user);
  }, [user]);

  return (
    <Avatar
      color="gray.900"
      name={currentUser?.username}
      bg="gray.200"
      src={
        currentUser
          ? `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/user/avatar/${currentUser?.avatarID}`
          : null
      }
      size={size}
      // cursor="pointer"
      onClick={onClick}
    />
  );
};

export default UserAvatar;
