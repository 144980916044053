import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  Avatar,
  Badge,
  Button,
  Textarea,
  HStack,
  IconButton,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { AiOutlineSend } from "react-icons/ai";
import request from "../api";
import { useTranslation } from "react-i18next";
export default function AskQuestionTemplate({
  id: courseID,
  username,
  avatarID,
  karma,
  fetchQuestions,
  fetchCourseData,
  updateUser,
  setUpdateUser,
}) {
  const [question, setQuestion] = useState("");
  const toast = useToast();
  const { t } = useTranslation();
  const handleQuestion = async () => {
    if (question.trim() == "") return;
    let response;
    try {
      response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${courseID}/question`,
        "POST",
        {
          question: question.trim(),
        }
      );
     
      toast({
        title: t("question_posted"),
        description: t("answer_soon"),
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      fetchQuestions();
      fetchCourseData();
      setUpdateUser(!updateUser);
    } catch (error) {
      toast({
        title: "Damn, something went wrong...",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error(error);
    }
    try {
      await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${courseID}/translate`,
        "POST",
        { text: question.trim(), elementID: response.id, elementType: "question" }
      );
      setQuestion("");
    } catch (error) {
      setQuestion("");
    }
  };

  return (
    <Flex
      alignItems="center"
      px={2}
      py={1}
      bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
      shadow="lg"
      borderRadius="lg"
      mb={2}
    >
      {/* log url of avatar */}
      <Avatar
        color="gray.900"
        name={username || "User"}
        src={avatarID ? `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/user/avatar/${avatarID}` : null}
        size="md"
        mr={2}
      />
      <Box flex="1">
        <HStack spacing={2} mb={2}>
          <Text fontWeight="bold">{username || "User"}</Text>
          <Badge colorScheme="grey">🔥 {karma || 0}</Badge>
        </HStack>
        <HStack spacing={0} align="center">
          <Textarea
            placeholder={t("ask_a_question")}
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            mb={2}
            resize={"vertical"}
            rows={1}
            height="auto"
            size={"xs"}
            fontSize={"sm"}
            mr={2}
          />
          {question.trim() !== "" && (
            <IconButton
              p={0}
              m={0}
              icon={<AiOutlineSend />}
              size={"sm"}
              fontSize={"2xl"}
              variant="ghost"
              color="main.primary"
              onClick={handleQuestion}
            />
          )}
        </HStack>
      </Box>
    </Flex>
  );
}
