import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Home from './pages/Home';

import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Course from './pages/Course';
import Profile from './pages/Profile';
import Admin from './pages/Admin';
import SearchPage from './pages/SearchPage';
import ContactForm from './components/ContactFormComponent';
import VerifyEmail from './pages/VerifyEmail';
import NotFound from './pages/NotFound';
import PrivateRoutes from './PrivateRoutes';
import Imprint from './pages/Imprint';
import TermsOfService from './pages/TermsOfService';
import LeaderboardPage from './pages/LeaderboardPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import SupportPage from './pages/SupportUs';
// import DefaultHelmet from './DefaultHelmet';

function PageRouter() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/sign-up" element={<SignUp />} />
      <Route exact path="/course/:courseID" element={<Course />} />
      <Route exact path="/sign-in" element={<SignIn />} />
      <Route exact path="/forgot-password" element={<ForgotPassword />} />
      <Route exact path="/reset-password" element={<ResetPassword />} />
      <Route exact path="/courses" element={<SearchPage />} />
      <Route exact path="/contact-form" element={<ContactForm />} />
      <Route exact path="/verify-email" element={<VerifyEmail />} />
      <Route exact path="/imprint" element={<Imprint />} />
      <Route exact path="/terms-of-service" element={<TermsOfService />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route exact path="/leaderboard" element={<LeaderboardPage />} />
      <Route exact path="/support-us" element={<SupportPage />} />
      <Route element={<PrivateRoutes requiredRoles={["STUDENT", "ADMIN", "MODERATOR"]} redirect="/" />}>
        <Route element={<Profile />} exact path="/profile" />
      </Route>
      <Route element={<PrivateRoutes requiredRoles={["ADMIN"]} redirect="/" />}>
        <Route element={<Admin />} exact path="/admin" />
      </Route>
      <Route exact path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PageRouter;
