import React, { useContext } from 'react';
import { Box, useMediaQuery } from '@chakra-ui/react';
import Navbar from '../components/Navbar';
import BottomBar from '../components/BottomBar';
import AuthContext from '../AuthContext';
import { useTranslation } from 'react-i18next';

const GermanSupport = () => (
  <Box>
    {/* Überschrift */}
    <Box as="h1" fontSize="3xl" fontWeight="bold" mb={4}>
      Unterstütze uns
    </Box>

    {/* Einführung */}
    <Box as="p" mb={6}>
      Du willst uns unterstützen? Stark! Wir brauchen und freuen uns über jede Art der Unterstützung!
    </Box>

    {/* Wie kann ich euch unterstützen? */}
    <Box as="h2" fontSize="2xl" fontWeight="semibold" mb={2}>
      Wie kann ich euch unterstützen?
    </Box>

    {/* Sei aktiv */}
    <Box as="h3" fontSize="xl" fontWeight="medium" mt={4} mb={2}>
      Sei aktiv!
    </Box>
    <Box as="p" mb={4}>
      Am meisten unterstützt du uns (und deine Kommilitonen), indem du ein aktiver User bist. Das heißt, 
      dass du nicht nur Ratings von anderen Studenten liest, sondern auch selbst alle deine Kurse bewertest, 
      die du belegt hast. Vor allem bei Kursen, die noch gar keine Bewertung haben, ist es extrem hilfreich, 
      wenn diese ihre erste Bewertung bekommen. Wir wissen, dass das auch ein bisschen nervig sein kann, 
      aber nur so kann eine Community-Plattform wie diese funktionieren. Und nur so kannst auch du von 
      anderen Ratings profitieren.
    </Box>

    {/* Sag deinen Freunden Bescheid */}
    <Box as="h3" fontSize="xl" fontWeight="medium" mt={4} mb={2}>
      Sag deinen Freunden Bescheid!
    </Box>
    <Box as="p" mb={4}>
      Nichts ist wichtiger als Mundpropaganda. Damit wir so viele Studenten wie möglich erreichen können, 
      sind wir darauf angewiesen, dass du uns auch deinen Freunden empfiehlst, die bisher noch nichts von 
      ClassRate gehört haben. In deinem Profil kannst du ganz einfach einen Invite-Link erstellen, mit dem 
      du und dein Freund sogar noch ein bisschen Ehre sammeln könnt.
    </Box>

    {/* Hilf uns, ClassRate noch besser zu machen */}
    <Box as="h3" fontSize="xl" fontWeight="medium" mt={4} mb={2}>
      Hilf uns, ClassRate noch besser zu machen!
    </Box>
    <Box as="p" mb={4}>
      Du hast einen Bug entdeckt oder dir fehlt noch irgendein Feature? Her damit! Wir sind auch nur 
      Studenten, die keinen fehlerfreien Code produzieren – da sind Bugs unvermeidlich. Hast du außerdem 
      einen Vorschlag oder Wunsch, wie wir ClassRate noch besser machen können? Dann melde dich einfach 
      über das Kontaktformular oder schreib uns direkt eine Mail an <a href="mailto:support@classrate.de" style={{ color: 'blue', textDecoration: 'underline' }}>support@classrate.de</a>. Willst du 
      darüber hinaus direkt mit anpacken und uns bei der Entwicklung unterstützen? Her damit, wir können 
      jede zusätzliche Unterstützung gebrauchen. Schreib uns einfach.
    </Box>

    {/* Ohne Moos nichts los */}
    <Box as="h3" fontSize="xl" fontWeight="medium" mt={4} mb={2}>
      Ohne Moos nichts los
    </Box>
    <Box as="p" mb={4}>
      Neben viel Zeit und Nerven kostet das Betreiben einer WebApp leider auch ein bisschen Geld, das man 
      als Student nicht unbedingt in Massen rumliegen hat. Aktuell belaufen sich die Serverkosten auf 
      etwa 20 € im Monat. Da wir an die Idee einer unabhängigen Community-Plattform glauben, haben wir 
      aktuell nicht geplant, Werbung zu schalten oder die Nutzung kostenpflichtig zu machen (ClassRate 
      bleibt immer kostenlos nutzbar!). Das heißt, dass wir die Serverkosten aktuell aus eigener Tasche 
      zahlen müssen. Wenn dir ClassRate geholfen hat, würden wir uns über ein lecker Bierchen oder ein 
      anderes Kaltgetränk freuen! Das Budget dafür könnt ihr uns über PayPal schicken. Jeder Cent hilft! 
      <br />
      <a href="https://paypal.me/derpipo" style={{ color: 'blue', textDecoration: 'underline' }}>paypal.me/derpipo</a>
    </Box>
  </Box>
);

const EnglishSupport = () => (
  <Box>
    {/* Heading */}
    <Box as="h1" fontSize="3xl" fontWeight="bold" mb={4}>
      Support Us
    </Box>

    {/* Introduction */}
    <Box as="p" mb={6}>
      You want to support us? Awesome! We need and appreciate every kind of help!
    </Box>

    {/* How can I support you? */}
    <Box as="h2" fontSize="2xl" fontWeight="semibold" mb={2}>
      How can I support you?
    </Box>

    {/* Be active */}
    <Box as="h3" fontSize="xl" fontWeight="medium" mt={4} mb={2}>
      Be active!
    </Box>
    <Box as="p" mb={4}>
      You support us (and your fellow students) the most by being an active user. This means not only 
      reading ratings from others, but also writing reviews for all the courses you've taken. 
      Especially if a course has no reviews yet, it's extremely helpful if it gets its first rating. 
      We know it can be a bit annoying, but that's the only way a community-driven platform can work. 
      And that's how you can benefit from others' ratings, too.
    </Box>

    {/* Tell your friends */}
    <Box as="h3" fontSize="xl" fontWeight="medium" mt={4} mb={2}>
      Tell your friends!
    </Box>
    <Box as="p" mb={4}>
      Nothing is more important than word of mouth. To reach as many students as possible, we rely on 
      you recommending us to friends who haven't heard of ClassRate yet. In your profile, you can 
      easily create an invite link to share, so both you and your friend can earn a bit of fame.
    </Box>

    {/* Help us make ClassRate even better */}
    <Box as="h3" fontSize="xl" fontWeight="medium" mt={4} mb={2}>
      Help us make ClassRate even better!
    </Box>
    <Box as="p" mb={4}>
      Found a bug or missing a feature? Bring it on! We're just students too, so bugs are inevitable. 
      If you have any suggestions or wishes for improvements, just get in touch via the contact form 
      or email us directly at <a href="mailto:support@classrate.de" style={{ color: 'blue', textDecoration: 'underline' }}>support@classrate.de</a>. Want to jump right in and help with development? 
      Let us know — we can use all the extra support we can get.
    </Box>

    {/* No dough, no show */}
    <Box as="h3" fontSize="xl" fontWeight="medium" mt={4} mb={2}>
      No dough, no show
    </Box>
    <Box as="p" mb={4}>
      Besides time and effort, running a WebApp costs a bit of money, which we students don't always 
      have lying around. Our server costs are currently about €20 a month. Since we believe in the 
      idea of an independent community platform, we don't plan on adding ads or charging for use 
      (ClassRate will always remain free!). That means we're paying the server costs out of our own 
      pockets at the moment. If ClassRate has helped you, we'd appreciate a tasty beer or any other 
      cold drink! You can send us the cost of that via PayPal. Every cent helps! 
      <br />
      <strong>paypal.me/derpipo</strong>
    </Box>
  </Box>
);

const SupportPage = () => {
  const allowedRoles = ["ADMIN", "STUDENT", "MODERATOR"];
  const { isSignedIn, role } = useContext(AuthContext);
  const { i18n } = useTranslation();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const isGerman = i18n.language === "de";

  return (
    <Box px={isLargerThan768 && 40}>
      <Navbar loggedIn={isSignedIn && allowedRoles.includes(role)} />
      <Box px={10} py={5} mt={20} maxW="100vw">
        {isGerman ? <GermanSupport /> : <EnglishSupport />}
      </Box>
      <BottomBar />
    </Box>
  );
};

export default SupportPage;
